:root {
    --primary-color: #ffead9;
    --secondary-color: #ff9d76;
    --tertiary-color: #ff7d5f;

    box-sizing: border-box;
    margin: 0;
    padding: 0;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html body {
  background-color: var(--primary-color);
}
