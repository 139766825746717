.blog-container {
    display: block;
    height: 100vh;
  }

.blog-post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
}

.blog-post-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    margin-bottom: 1rem;
}

.post-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    align-self: center;
}