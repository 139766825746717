
.navigation-container {
  position: relative
}

.navigation-button {
  position: fixed;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0px 0 0; 
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 300px;
  z-index: 600;
  background-color: var(--secondary-color);

  .list-group-item {
    background-color: var(--secondary-color);
  }
}




@media (min-width: 991.98px) {
  
  .show-on-desktop {
    display: block !important;
  }

  .hide-on-desktop {
    display: none !important;
  }

  .sidebar {
    height: calc(var(--vh, 1vh) * 100);
  }
}


@media (max-width: 991.98px) {
  .sidebar {

      // position: absolute;
      padding-top: 0;
      top: 0;
      bottom: 0;
      left: 0px;
      width: 200px;
      height: calc(var(--vh, 1vh) * 100);
      z-index: 598;
      transition: transform 0.3s ease-in-out;
      transform: translateX(0);
  }
  .sidebar.show {
    left: 0;
  }
}
.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}


